import Swiper, { Navigation } from 'swiper';

const timeline = document.querySelector('.timeline');
if (timeline) {
  const slider = timeline.querySelector('.swiper');
  const prevButton = timeline.querySelector('.timeline-prev-button');
  const nextButton = timeline.querySelector('.timeline-next-button');

  new Swiper(slider, {
    modules: [Navigation],
    slidesPerGroup: 3,
    slidesPerView: 3,
    spaceBetween: 80,
    navigation: {
      prevEl: prevButton,
      nextEl: nextButton
    }
  });
}

// Tabs
const tabsWrapper = document.querySelectorAll('.tabs-wrapper');
if (tabsWrapper) {
  tabsWrapper.forEach((tabWrapper) => {
    const tabButtons = tabWrapper.querySelectorAll('.tab-menu a');
    const tabPanels = tabWrapper.querySelectorAll('.tab-panel');

    tabButtons[0].classList.add('active');
    tabPanels[0].classList.add('active');

    tabButtons.forEach((tabButton) => {
      tabButton.addEventListener('click', (e) => {
        e.preventDefault();
        tabButton.classList.add('active');

        [...tabButtons].filter((button) => {
          if (button !== tabButton) {
            button.classList.remove('active');
          }
        });

        const panelID = tabButton.getAttribute('href');
        const targetPanel = tabWrapper.querySelector(panelID);
        targetPanel.classList.add('active');

        [...tabPanels].filter((panel) => {
          if (panel !== targetPanel) {
            panel.classList.remove('active');
          }
        });
      });
    });
  });
}
